import moment from "moment";
import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  ApiKey,
  ApiUrl,
  WebUrl,
  SessionKey,
  _MAIN_WALLET_ID,
  DisplayDevice,
  TransactionStatus,
  AlertTypes,
} from "../../util/Constant";
import ApiEngine from "../../util/ApiEngine.js";
import {
  showResponseMessage,
  setBusy,
  setIdle,
  bindMember,
  bindGames,
  setBanners,
  setGameSearchText,
  setGameQuery,
  setPageSize,
  setY,
  setThemeName,
  showMessage,
  setDepositModal,
  bindGamesRecommended,
  bindGamesFeatured,
  setLatestBets,
  setGameLobbyModal,
  bindCompanyIcon,
  setLoginModal,
  setRegisterModal,
  setDownlineModal,
  setShareReferralModal,
} from "../../redux/AppAction.js";
import {
  stringIsNullOrEmpty,
  isObjectEmpty,
  numberWithCurrencyFormat,
  createFormBody,
} from "../../util/Util";
import {
  map,
  find,
  filter,
  debounce,
  groupBy,
  chain,
  isEmpty,
  get,
  head,
  includes,
} from "lodash";
import Slider from "react-slick";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import SwiperCore, { Autoplay } from 'swiper';
import { themeIcons } from "config/theme";
import VIcon from "components/custom/Icon";
// import Spinner from "react-bootstrap/Spinner";
import {
  Modal,
  ModalBody,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Spinner,
  Alert,
} from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import Icon from "@mdi/react";
import { mdiContentCopy } from "@mdi/js";
import classnames from "classnames";
import PinInput from "react-pin-input";
import Header from "components/template_1/Header";
import Footer from "components/template_1/Footer";
import parse from "react-html-parser";
import LoadingGames from "components/template_1/LoadingGames";
import LoadingPlaceholder from "components/template_1/LoadingPlaceholder";
import SweetAlert from "react-bootstrap-sweetalert";
import GameLobbyModal from "components/template_1/GameLobbyModal";
import PlaceholderLoading from "react-placeholder-loading";
import UMIcon2 from "../../assets/img/icon/icon-repair.svg";
import FallBackImage from "components/template_1/FallBackImage";
import 'react-loading-skeleton/dist/skeleton.css'
import Skeleton from "react-loading-skeleton";
import SpeakerIcon from "components/custom/SpeakerIcon";

const Login = (props) => {
  var { t } = useTranslation();
  var _history = useHistory();
  var _dispatch = useDispatch();
  const slotSlickRef = React.useRef(null);
  const featuredSlickRef = React.useRef(null);
  const liveSlickRef = React.useRef(null);

  // Swiper Plugins
  SwiperCore.use([Autoplay]);

  var _codeRef = useRef();
  var _linkRef = useRef();
  const _ALERT_TIMEOUT = 3000;

  var { heartBeat } = useSelector((state) => state.authState);
  const memberData = useSelector((state) => state.appState.member);
  let usernameRef = useRef();
  let passwordRef = useRef();
  const _FIRST_TAB = 1;
  const _SECOND_TAB = 2;

  let isAutoScrolling = false;
  const container = document.getElementById("t3-scroll-row");
  const [isStopScroll, setIsStopScroll] = useState(false);
  const [gameCategoryActive, setGameCategoryActive] = useState({
    id: -1,
    title: "All",
  });
  const [openMobileSideMenu, setOpenMobileSideMenu] = useState(false);

  const [bannersList, setBannersList] = useState(
    useSelector((state) => state.appState.banners)
  );
  const [gameCategories, setGameCategories] = useState([]);
  const [games, setGames] = useState(
    useSelector((state) => state.appState.games)
  );
  const [popupBannerImage, setPopupBannerImage] = useState("");
  const [selectedGameName, setSelectedGameName] = useState("");
  const [masterProductId, setMasterProductId] = useState(0);
  const [isApp, setIsApp] = useState(false);
  const [appDeepLink, setAppDeepLink] = useState("");
  const [packageNameKeyword, setPackageNameKeyword] = useState("");
  const [selectedGameHasOwnLobby, setSelectedGameHasOwnLobby] = useState(false);
  const [selectedGameIsSeamless, setSelectedGameIsSeamless] = useState(false);
  const [walletList, setWalletList] = useState([]);
  const [bonus, setBonus] = useState(0);
  const [commission, setCommission] = useState(0);
  const [gameWalletBalanceBeforeTransfer, setGameWalletBalanceBeforeTransfer] =
    useState(0);
  const [masterProductContent, setMasterProductContent] = useState("");
  const [fromOptions, setFromOptions] = useState([]);
  const [toOptions, setToOptions] = useState([]);
  const [isNewWindow, setIsNewWindow] = useState(false);
  const [showPregamePopup, setShowPregamePopup] = useState(false);
  const [productGameId, setProductGameId] = useState(0);
  const [toLobbyBtn, SetToLobbyBtn] = useState(false);
  const [rolloverBalance, setRolloverBalance] = useState("");
  const [rolloverTarget, setRolloverTarget] = useState("");
  const [productCommRate, setProductCommRate] = useState([]);
  const [appUrl, setAppUrl] = useState("");
  const [clubFee, setClubFee] = useState(0);
  const [mpData, setMpData] = useState([]);
  const [showAppCred, setShowAppCred] = useState(false);
  const [appUsername, setAppUsername] = useState("");
  const [appPassword, setAppPassword] = useState("");
  const [isDisabled, setDisabled] = useState(false);
  const [isTransferSuccess, setIsTransferSuccess] = useState(false);
  const [transferShow, setTransferShow] = useState(false);
  const [toGameAmount, setToGameAmount] = useState(0);
  const [promoId, setPromoId] = useState("");
  const [promoCodeList, setPromoCodeList] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [promoShow, setPromoShow] = useState(false);
  const [paidAmount, setPaidAmount] = useState();
  const [transferDate, setTransferDate] = useState();
  const [transcactionNumber, setTranscactionNumber] = useState();
  const [gameList, setGameList] = useState([]);
  const [gameActiveTab, setGameActiveTab] = useState(_FIRST_TAB);
  const [showModalLogin, setShowModalLogin] = useState(false);
  const [showModalRegister, setShowModalRegister] = useState(false);
  const [hideLoginPassword, setHideLoginPassword] = useState(true);
  const [registerSteps, setRegisterSteps] = useState(1);
  const [pinNumberFocused, setPinNumberFocused] = useState(0);
  const [isLogin, setIsLogin] = useState(true);
  const openMenu = useSelector((state) => state.appState.openMenu);
  const [activeLanguage, setActiveLanguage] = useState("");
  const [activeLanguageId, setActiveLanguageId] = useState(0);
  const activeLanguageCode = useSelector(
    (state) => state.languageState.languageId
  );
  const [transLoading, setTransLoading] = useState();
  const [content, setContent] = useState("");
  const [recommendedSlotGames, setRecommendedSlotGames] = useState(
    useSelector((state) => state.appState.gamesRecommended.slotGames)
  );
  const [recommendedSlotGamesCount, setRecommendedSlotGamesCount] = useState(
    useSelector((state) => state.appState.gamesRecommended.casinoGamesCount)
  );
  const [initFlag, setInitFlag] = useState(false);
  

  // const [featuredSlotGames, setFeaturedSlotGames] = useState([]);

  const [featuredSlotGames, setFeaturedSlotGames] = useState(
    useSelector((state) => state.appState.gamesFeatured.slotGames)
  );

  const [featuredSlotGamesCount, setFeaturedSlotGamesCount] = useState(
    useSelector((state) => state.appState.gamesFeatured.slotGamesCount)
  );
  const [recommendedCasinoGames, setRecommendedCasinoGames] = useState(
    useSelector((state) => state.appState.gamesRecommended.casinoGames)
  );
  const [recommendedCasinoGamesCount, setRecommendedCasinoGamesCount] =
    useState(
      useSelector((state) => state.appState.gamesRecommended.casinoGamesCount)
    );

  // const [latestBets, setLatestBets] = useState("");
  const latestBets = useSelector((state) => state.appState.latestBets);
  const [dragging, setDragging] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [balanceLoading, setBalanceLoading] = useState(false);
  const [transferLoading, setTransferLoading] = useState(false);
  const providerList = useSelector((state) => state.appState.provider);
  const gamesRecommended = useSelector(
    (state) => state.appState.gamesRecommended
  );

  const [showDepositSuccess, setShowDepositSuccess] = useState(false);
  const [showDepositFailed, setShowDepositFailed] = useState(false);

  const walletsDetails = useSelector((state) => state.appState.walletsDetails);
  const companyIcons = useSelector((state) => state.appState.companyIcons);
  const [alertIcon, setAlertIcon] = useState();
  var companyLanguages = useSelector((state) => state.appState.languages);
  const [latestDepositList, setLatestDepositList] = useState();
  const [latestWithdrawalList, setLatestWithdrawalList] = useState();
  const [balanceRefresh, setBalanceRefresh] = useState(false);
  const [teamBanner, setTeamBanner] = useState();
  const [coinIcon, setCoinIcon] = useState();
  const [uplineLoading, setUplineLoading] = useState(false);
  const [showCodeAlert, setShowCodeAlert] = useState(false);
  const _APPEND_REGISTER_URL = "/register?code=";
  const [showAnnouncementModal, setShowAnnouncementModal] = useState(false);
  const [logo, setLogo] = useState();
  const [announcementList, setAnnouncementsList] = useState([]);
  const [minimumDeposit, setMinimumDeposit] = useState(0);
  const [minimumWithdrawal, setMinimumWithdrawal] = useState(0);
  const [noDataBg, setNoDataBg] = useState();
  const companyCurrency = useSelector((state) => state.appState.companyCurrency);
  const [rolloverWarning, setRolloverWarning] = useState(false);
  const [rolloverData, setRolloverData] = useState([]);
  const [registerUrl, setRegisterUrl] = useState("");
  const [copy1, setCopy1] = useState(false);
  const [copy2, setCopy2] = useState(false);
  const [referralBanner, setReferralBanner] = useState("");
  const [referralBannerMobile, setReferralBannerMobile] = useState("");
  const [payoutList, setPayoutList] = useState([]);
  const [defaultGameIcon, setDefaultGameIcon] = useState();
  const [bigWinList, setBigWinList] = useState([]);
  const [bannerLoading, setBannerLoading] = useState(true)
  const companyCurrencySymbol = useSelector((state) => state.appState.currencySymbol);

  const arraySlider = Array(8).fill()

  const {
    handleSubmit: handleSubmitPopup,
    register: registerPopup,
    control: controlPopup,
    watch: watchPopup,
    getValues: getValuesPopup,
    setValue: setValuePopup,
    errors: errorsPopup,
    reset: resetPopup,
  } = useForm();
  var _userData = useSelector((state) => state.authState.userData);
  var referralDetails = useSelector((state) => state.appState.member.referralCode);

	const payoutSlick = {
		className: "slider variable-width",
		dots: false,
		infinite: true,
		// centerMode: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		variableWidth: true,
		draggable:false,
		autoplay: true,
		speed: 1000,
		autoplaySpeed: 3000,
		arrows:false,
		responsive: [
			{

        breakpoint: 768,
        settings: {
          className: "",

          variableWidth: false,

          slidesToShow: 3,
          slidesToScroll: 1,
          vertical: true,
          verticalSwiping: true,
          draggable:false,
          autoplay: true,
          touchMove: false,
       
        },
      }
    ]
  };

  const [providerSlick, setProviderSlick] = useState({
    slidesToShow: 4,
    dots: false,
    arrows: false,
    swipe: true,
    infinite: true,
    swipeToSlide: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 5000,
  });

  // Detect window size
  const handleWindowSizeChange = () => {
    if (window.innerWidth < 900) {
      setProviderSlick({
        ...providerSlick,
        slidesToShow: 2,
      });
    } else {
      setProviderSlick({
        ...providerSlick,
        slidesToShow: 4,
      });
    }
  };
  useEffect(() => {
    handleWindowSizeChange()
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const slotSlick = {
    slidesToShow: 1,
    dots: false,
    arrows: false,
    lazyLoad: true,
    swipe: true,
    infinite: false,
    swipeToSlide: true,
    speed: 1000,
    beforeChange: () => setDragging(true),
    afterChange: () => setDragging(false),
  };

  useEffect(() => {
    getAnnouncement();
  }, []);

  useEffect(() => {
    if (heartBeat) {
      getMinumumDepositWithdrawal();
    }
  }, [heartBeat]);

  useEffect(() => {
    if (companyIcons.length > 0) {
      getCompanyIcon();
    }
  }, [companyIcons]);

  useEffect(() => {
    if (companyLanguages != undefined) {
      const tempLangCode = get(companyLanguages[0], "code");
      const tempLangId = get(companyLanguages[0], "id");
      if (
        localStorage.getItem(SessionKey._LANGUAGE) &&
        localStorage.getItem(SessionKey._LANGUAGE) !== "undefined"
      ) {
        let tempLang = companyLanguages.filter(
          (x) => x.code == localStorage.getItem(SessionKey._LANGUAGE)
        )[0];

        if (tempLang != undefined) {
          setActiveLanguage(tempLang.code);
          setActiveLanguageId(tempLang.id);
        } else {
          setActiveLanguage(tempLangCode);
          setActiveLanguageId(tempLangId);
        }
      } else {
        setActiveLanguage(tempLangCode);
        setActiveLanguageId(tempLangId);
      }
    } else {
      setActiveLanguage("en");
      setActiveLanguageId(1);
    }
  }, [companyLanguages, activeLanguageCode]);

  useEffect(() => {
    if (activeLanguageId > 0) {
      getBanners();
      getMarqueeTagContent();
      getNewGames(); 
    }
  }, [activeLanguageId]);

  useEffect(() => {
    init();
    getNewGames();
    // getLatestBets();
  }, []);



  useEffect(() => {
    setRegisterUrl(window.location.origin + _APPEND_REGISTER_URL + referralDetails);
  }, [referralDetails]);


  useEffect(() => {
    if (announcementList.length > 0) {
      setShowAnnouncementModal(true);
    }
  }, [announcementList]);

  const closePopUp = () => {
    // setting key "seenPopUp" with value true into localStorage
    // localStorage.setItem("seenPopUp", true);
    // setting state to false to not display pop-up
    setShowAnnouncementModal(false);
  };

  async function init() {
    // if (heartBeat) {
    // 	bindMember(_dispatch);
    // }

    const search = window.location.search;
    const params = new URLSearchParams(search);

    if (params.get("transactionStatus")) {
      let transactionStatus = params.get("transactionStatus");

      if (transactionStatus == TransactionStatus._APPROVED) {
        setShowDepositSuccess(true);
      } else if (transactionStatus == TransactionStatus._REJECTED) {
        setShowDepositFailed(true);
      }
      // params.get('transactionStatus') == TransactionStatus._APPROVED ? setShowDepositSuccess(true) :
      // 	params.get('transactionStatus') == TransactionStatus._REJECTED ? setShowDepositFailed(true) : "";
    }
    getWithdrawalDepositTransaction();
    getBigWin()
    getRecentPayout()
    
    var responseJson = await ApiEngine.get(
      ApiUrl._API_GET_CATEGORY_GROUP_GAME_DASHBOARD
    );

    setInitFlag(true);

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setGames(responseJson[ApiKey._API_DATA_KEY]);
      _dispatch(bindGames(responseJson[ApiKey._API_DATA_KEY]));
      /// preload
      responseJson[ApiKey._API_DATA_KEY].map((game, index) => {
        let image = new Image();
        image.src = game["image"];

        window[game["image"]] = image;
      });
    } else {
      throw responseJson[ApiKey._API_MESSAGE_KEY];
    }
    
  }

  const getWithdrawalDepositTransaction = async () => {
    setTransLoading(true)
    var responseJson2 = await ApiEngine.get(
      ApiUrl._API_GET_TOP_DEPOSIT_WITHDRAWAL
    );

    if (responseJson2[ApiKey._API_SUCCESS_KEY]) {
      // _dispatch(setBusy());
      const latestDepositData = filter(responseJson2[ApiKey._API_DATA_KEY], {
        transactionTypeId: 1,
      });
      const latestWithdrawalData = filter(responseJson2[ApiKey._API_DATA_KEY], {
        transactionTypeId: 2,
      });

      setLatestDepositList(latestDepositData);
      setLatestWithdrawalList(latestWithdrawalData);
      // _dispatch(setIdle());
    } else {
      throw responseJson2[ApiKey._API_MESSAGE_KEY];
    }

    setTransLoading(false)


  }

  const getRecentPayout = async () => {
    var responseJsonPayout = await ApiEngine.get(
      ApiUrl._API_GET_RECENT_PAYOUT
    );
    if (responseJsonPayout[ApiKey._API_SUCCESS_KEY]) {
      setPayoutList(responseJsonPayout[ApiKey._API_DATA_KEY])
    }
  }

  const getBigWin = async () => {
    var responseJsonBigWin = await ApiEngine.get(
      ApiUrl._API_GET_BIG_WIN
    );
    if (responseJsonBigWin[ApiKey._API_SUCCESS_KEY]) {

      var bigWinResult=responseJsonBigWin[ApiKey._API_DATA_KEY];

      if(bigWinResult.length % 2 !== 0){
        bigWinResult.pop();
      }
      setBigWinList(bigWinResult)
    } 
  }

  const getBanners = async () => {
    setBannerLoading(true)
    let responseJson = await ApiEngine.get(
      ApiUrl._API_GET_USER_BANNER +
      "?page=/dashboard" +
      "&languagecode=" +
      activeLanguage
    );
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setBannersList(responseJson[ApiKey._API_DATA_KEY]);
      _dispatch(setBanners(responseJson[ApiKey._API_DATA_KEY]));
    }
    setBannerLoading(false)

  };

  async function getMarqueeTagContent() {
    let responseJson = await ApiEngine.get(
      ApiUrl._API_GET_MARQUEE_TAG_CONTENT_BY_PLATFORM +
      "?MarqueeTagId=1" +
      "&LanguageId=" +
      activeLanguageId +
      "&Platform=" +
      "MemberApp"
    );
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      responseJson[ApiKey._API_DATA_KEY].map((option) => {
        setContent(option["content"]);
      });
    }
  }

  async function getNewGames() {
    let responseJson = await ApiEngine.get(
      ApiUrl._API_HOMEPAGE_PRODUCTS +
      "?languageId=" +
      activeLanguageId
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      /////get recommended Slots
      const data = responseJson[ApiKey._API_DATA_KEY].recommendedGames.filter(
        (x) => x.categoryTitle === "Slots"
      );
      setRecommendedSlotGamesCount(data.length);

      let groupedArray = [];

      for (let i = 0; i < data.length; i += 12) {
        groupedArray.push(data.slice(i, i + 12));
      }
      setRecommendedSlotGames(groupedArray);

      const featuredGame = responseJson[
        ApiKey._API_DATA_KEY
      ].isFeaturedGame.filter((x) => x.categoryTitle === "Slots");

      setFeaturedSlotGamesCount(featuredGame.length);

      let featuredGameGrouped = [];

      for (let i = 0; i < featuredGame.length; i += 12) {
        featuredGameGrouped.push(featuredGame.slice(i, i + 12));
      }
      setFeaturedSlotGames(featuredGameGrouped);

      /////get recommended Casino
      const casinoRecommendedGame = responseJson[
        ApiKey._API_DATA_KEY
      ].recommendedGames.filter((x) => x.categoryTitle === "Casino");

      setRecommendedCasinoGamesCount(casinoRecommendedGame.length);
      let casinoGameGrouped = [];

      for (let i = 0; i < casinoRecommendedGame.length; i += 12) {
        casinoGameGrouped.push(casinoRecommendedGame.slice(i, i + 12));
      }
      setRecommendedCasinoGames(casinoGameGrouped);

      _dispatch(
        bindGamesRecommended({
          slotGamesCount: data.length,
          slotGames: groupedArray,
          casinoGamesCount: casinoRecommendedGame.length,
          casinoGames: casinoGameGrouped,
        })
      );

      _dispatch(
        bindGamesFeatured({
          slotGamesCount: featuredGame.length,
          slotGames: featuredGameGrouped,
        })
      );
    }
  }

  async function startGame(
    gameId,
    hasOwnLobby,
    masterProductId,
    gameName,
    isSeamless,
    isApp,
    appUrl,
    appDeepLink,
    isNewWindow,
    packageNameKeyword
  ) {
    setSelectedGameName(gameName);
    setMasterProductId(masterProductId);
    setIsApp(isApp);
    setAppDeepLink(appDeepLink);
    setPackageNameKeyword(packageNameKeyword);
    setSelectedGameHasOwnLobby(hasOwnLobby);
    setSelectedGameIsSeamless(isSeamless);

    if (isApp && !stringIsNullOrEmpty(appUrl)) {
      setAppUrl(appUrl);
    }
    if (hasOwnLobby !== true) {
      if (isSeamless == false) {
        setProductGameId(gameId);
        _dispatch(setGameLobbyModal(true));
      } else {
        var breadcrumbData = [{
          pageName: t("HOME"),
          location: _history.location.pathname + _history.location.search,
          state: _history.location.state
        }];

        var path = WebUrl._URL_IFRAME_GAME_PAGE + "?gameId=" + gameId;
        _history.push(path, {
          breadcrumbData: breadcrumbData
        });
      }
    } else {
      if (isSeamless == false) {
        setIsLoading(true);
        var responseJson = await ApiEngine.get(
          ApiUrl._API_GET_NON_SEAMLESS_PRODUCT_BALANCE +
          "?masterProductId=" +
          masterProductId
        );
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          setWalletList(responseJson[ApiKey._API_DATA_KEY]);

          let tempFromOptions = [];
          let tempToOptions = [];

          responseJson[ApiKey._API_DATA_KEY].map((product) => {
            let option = {
              label: product.productName,
              value: product.productId,
              amount: parseFloat(product.balance),
            };
            if (product.productId === _MAIN_WALLET_ID) {
              tempFromOptions.push(option);
            } else if (product.productId === masterProductId) {
              setBonus(product.bonus);
              setCommission(product.commission);
              if (product.productName === "D2 Poker") {
                setClubFee(product.clubFee);
              }
              setGameWalletBalanceBeforeTransfer(product.balance);
              if (product.masterProductContent !== null) {
                setMasterProductContent(product.masterProductContent);
              } else {
                setMasterProductContent("");
              }
              tempToOptions.push(option);
            }
          });
          setFromOptions(tempFromOptions);
          setToOptions(tempToOptions);

        }

        setShowPregamePopup(true);
        getProductComRate(gameName);
        setProductGameId(gameId);
        SetToLobbyBtn(true);
        getRolloverProgress(gameId);

        var path = WebUrl._URL_GAME_LOBBY +
          "?name=" +
          gameName +
          "&id=" +
          gameId +
          "&languageId=" +
          memberData["languageSetting"];

        var breadcrumbData = [{
          pageName: t('HOME'),
          location: _history.location.pathname + _history.location.search,
          state: _history.location.state
        }, {
          pageName: gameName,
          location: path
        }];

        var state = {
          breadcrumbData: breadcrumbData
        }

        _history.push(path, state);
        setIsLoading(false);
      }
    }
  }

  async function getProductComRate(selectedGameName) {
    let apiUrl = ApiUrl._API_PRODUCT_COMM_RATE;
    let data = [];
    let responseJson = await ApiEngine.get(apiUrl);
    if (
      responseJson[ApiKey._API_SUCCESS_KEY] &&
      responseJson[ApiKey._API_DATA_KEY].length > 0
    ) {
      data = responseJson[ApiKey._API_DATA_KEY].filter(
        (x) => x.productName === selectedGameName
      );

      if (data.length > 0) {
        setProductCommRate(data[0]);
      }
    }
  }

  async function getRolloverProgress(masterProductId) {
    setRolloverBalance("");
    setRolloverTarget("");
    if (masterProductId != "0" && !stringIsNullOrEmpty(masterProductId)) {
      var responseJson = await ApiEngine.get(
        ApiUrl._API_GET_DEPOSIT_PROMOTION_ROLLOVER_PROGRESS +
        "?masterProductId=" +
        masterProductId
      );
      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        if (responseJson[ApiKey._API_DATA_KEY] != null) {
          if (responseJson[ApiKey._API_DATA_KEY].data != null) {
            setRolloverBalance(
              responseJson[ApiKey._API_DATA_KEY].data["rolloverBalance"]
            );
            setRolloverTarget(
              responseJson[ApiKey._API_DATA_KEY].data["rolloverTarget"]
            );
          }
        }
      }
    }
  }

  async function RefreshBalanceF(masterProductId) {
    setMasterProductId(masterProductId);
    setBalanceLoading(true);

    var responseJson = await ApiEngine.get(
      ApiUrl._API_GET_NON_SEAMLESS_PRODUCT_BALANCE +
      "?masterProductId=" +
      masterProductId
    );
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setWalletList(responseJson[ApiKey._API_DATA_KEY]);

      let tempFromOptions = [];
      let tempToOptions = [];

      responseJson[ApiKey._API_DATA_KEY].map((product) => {
        let option = {
          label: product.productName,
          value: product.productId,
          amount: parseFloat(product.balance),
        };
        if (product.productId === _MAIN_WALLET_ID) {
          tempFromOptions.push(option);
        } else if (product.productId === masterProductId) {
          setBonus(product.bonus);
          setCommission(product.commission);
          if (product.productName === "D2 Poker") {
            setClubFee(product.clubFee);
          }
          setGameWalletBalanceBeforeTransfer(product.balance);
          if (product.masterProductContent !== null) {
            setMasterProductContent(product.masterProductContent);
          } else {
            setMasterProductContent("");
          }
          tempToOptions.push(option);
        }
      });
      setFromOptions(tempFromOptions);
      setToOptions(tempToOptions);
      setBalanceLoading(false);

      setDisabled(true);
      setTimeout(() => setDisabled(false), 5000);
    }
  }

	const submitPopupForm = async (data, e) => {
		setTransferLoading(true);
		let params = {
			fromWallet: _MAIN_WALLET_ID,
			toWallet: masterProductId,
			amount: toGameAmount,
			promotionProductId: promoId,
		};

    let responseJson = await ApiEngine.post(
      ApiUrl._API_CREATE_WALLET_TRANSFER_TRANSACTION,
      createFormBody(params)
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      await updateWalletBalance(
        parseFloat(responseJson[ApiKey._API_DATA_KEY].amount).toFixed(2)
      );
      setPaidAmount(responseJson[ApiKey._API_DATA_KEY].amount);
      setTransferDate(moment().format("DD MMM YYYY, hh:mm A"));
      setIsTransferSuccess(true);
      setShowPregamePopup(true);
      setTransferShow(false);
      setTranscactionNumber(responseJson[ApiKey._API_DATA_KEY].transactionId);
      getRolloverProgress(masterProductId);
      e.target.reset();
    } else {
      _dispatch(
        showResponseMessage(
          responseJson[ApiKey._API_SUCCESS_KEY],
          responseJson[ApiKey._API_MESSAGE_KEY]
        )
      );
    }
    setTransferLoading(false);
  };

	async function getPromotion(masterProductId) {
		var responseJson = await ApiEngine.get(
			ApiUrl._API_GET_DEPOSIT_PROMOTION_BY_MASTER_PRODUCT_ID +
				"?masterProductId=" +
				masterProductId
		);
		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
			if (responseJson[ApiKey._API_DATA_KEY] != null) {
				setPromoCodeList(responseJson[ApiKey._API_DATA_KEY].data);
			}
		}
	}

  async function updateWalletBalance() {
    var responseJson = await ApiEngine.get(
      ApiUrl._API_GET_NON_SEAMLESS_PRODUCT_BALANCE
    );
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let tempFromOptions = [];
      let tempToOptions = [];
      responseJson[ApiKey._API_DATA_KEY].map((product) => {
        let option = {
          label: product.productName,
          value: product.productId,
          amount: parseFloat(product.balance),
        };
        if (product.productId === _MAIN_WALLET_ID) {
          tempFromOptions.push(option);
        } else if (product.productId === masterProductId) {
          let gameWalletBalance = parseFloat(product.balance);
          setGameWalletBalanceBeforeTransfer(gameWalletBalance);
          tempToOptions.push(option);
        }
      });
      setFromOptions(tempFromOptions);
      setToOptions(tempToOptions);
    }
  }

  function copyText(textType) {
    if (textType === "username") {
      usernameRef.current.select();
    } else if (textType === "password") {
      passwordRef.current.select();
    }
    document.execCommand("copy");
    //setShowCopyAlert(true);
    //setTimeout(() => setShowCopyAlert(false), [_ALERT_TIMEOUT]);
  }

  async function getLatestBets() {
    var responseJson = await ApiEngine.get(
      ApiUrl._API_GET_LATEST_BETS + "?count=30"
    );
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      _dispatch(setLatestBets(responseJson[ApiKey._API_DATA_KEY]));
    }
  }

  useEffect(() => {
    if (container) {
      startAutoScroll();
    }
  }, [container]);

  useEffect(() => {
    if (isStopScroll) {
      setTimeout(() => {
        startAutoScroll();
        setIsStopScroll(false);
      }, 500);
    }
  }, [isStopScroll]);

  function startAutoScroll() {
    isAutoScrolling = true;
    scrollToBottom();
    container.addEventListener("wheel", () => {
      stopAutoScroll();
    });
  }

  function stopAutoScroll() {
    isAutoScrolling = false;
    setIsStopScroll(true);
  }

  function scrollToBottom() {
    if (!isAutoScrolling) {
      return;
    }

    const start = container.scrollTop;
    const end = container.scrollHeight - container.clientHeight;
    const duration = 15000; // Set the duration in milliseconds

    const startTime = performance.now();

    function scroll(currentTime) {
      if (!isAutoScrolling) {
        return;
      }

      const elapsedTime = currentTime - startTime;

      const percentage = Math.min(1, elapsedTime / duration);
      const easeInOut = 0.5 - 0.5 * Math.cos(Math.PI * percentage);

      container.scrollTop = start + easeInOut * (end - start);

      if (percentage < 1) {
        requestAnimationFrame(scroll);
      }
    }

    requestAnimationFrame(scroll);
  }

  async function setTransactionValidForPromo(gameId) {
    _dispatch(setBusy());
    let responseJson = await ApiEngine.get(
      ApiUrl._API_UPDATE_TRANSACTION_VALID_FOR_PROMO
    );
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      // setIsValidForPromotion(false);
    }
    _dispatch(setIdle());
  }

  async function getCompanyIcon() {
    _dispatch(setBusy());
    const alert = find(companyIcons, { iconCategory: "alert" });
    if (alert) {
      setAlertIcon(alert.iconImage);
    }

    const gameCategories = [];
    const pathnamesToFilter = ["/game-list", "/sports", "/casino", "/slots", "/keno", "/fish", "/e-sport"];

    const gameList = filter(companyIcons, item => includes(pathnamesToFilter, item.pathname));
    if (gameList) {
      gameList.forEach(function (x) {
        gameCategories.push({
          id: x.iconName == "All" ? -1 : x.id,
          title: x.iconName,
          image: x.iconImage,
        });
      });
      setGameCategories(gameCategories);
    }

    const teamBanner = find(companyIcons, { iconCategory: "team_banner" });
    if (teamBanner) {
      setTeamBanner(teamBanner.iconImage);
    }

    const coinIcon = find(companyIcons, { iconCategory: "coin_icon" });
    if (coinIcon) {
      setCoinIcon(coinIcon.iconImage);
    }

    const logo = find(companyIcons, { iconCategory: "logo" });
    if (logo) {
      setLogo(logo.iconImage);
    }

    const noDataBg = find(companyIcons, { iconCategory: "no_data_background" });
    if (noDataBg) {
      setNoDataBg(noDataBg.iconImage);
    }

    const tempReferralBanner = find(companyIcons, { iconCategory: "referral_banner" });
    if (tempReferralBanner) {
      setReferralBanner(tempReferralBanner.iconImage);
    }

    const tempReferralBannerMobile = find(companyIcons, { iconCategory: "referral_banner_mobile" });
    if (tempReferralBannerMobile) {
      setReferralBannerMobile(tempReferralBannerMobile.iconImage);
    }

    const defaultGameIconTemp = filter(companyIcons, (icon) => {
      return icon.iconCategory === "default_game_icon";
    });
    if (defaultGameIconTemp) {
      setDefaultGameIcon(defaultGameIconTemp[0]?.iconImage);
    }

    _dispatch(setIdle());
  }

  async function getRestore() {
    setBalanceRefresh(true);
    let responseJson = await ApiEngine.get(ApiUrl._API_RESTORE_ALL_WALLET);

    _dispatch(
      showResponseMessage(
        responseJson[ApiKey._API_SUCCESS_KEY],
        responseJson[ApiKey._API_MESSAGE_KEY]
      )
    );
    setBalanceRefresh(false);
  }

  const copyItem = (item) => {
    navigator.clipboard.writeText(item.current.innerText);

    setCopy1(true)
    setTimeout(() => setCopy1(false), [_ALERT_TIMEOUT]);
  };
  const copyItem2 = (item) => {
    navigator.clipboard.writeText(item.current.innerText);

    setCopy2(true)
    setTimeout(() => setCopy2(false), [_ALERT_TIMEOUT]);
  };

  function copyUrl() {
    navigator.clipboard.writeText(registerUrl);
    setShowCodeAlert(true);
    setTimeout(() => setShowCodeAlert(false), [_ALERT_TIMEOUT]);
  }

  const getAnnouncement = async () => {
    var responseJson = await ApiEngine.get(
      ApiUrl._API_GET_ANNOUNCEMENT + "?languageId=" + activeLanguageId
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      //   setAnnouncements(responseJson[ApiKey._API_DATA_KEY]);
      let res = responseJson[ApiKey._API_DATA_KEY];

      let mapRes = map(res, (item, index) => {
        if (item.announcementLanguages.length > 0) {
          return head(item.announcementLanguages);
        } else {
          return item;
        }
      });

      setAnnouncementsList(mapRes);
    }
  };

  const getMinumumDepositWithdrawal = async () => {
    var responseJson = await ApiEngine.get(
      ApiUrl._API_GET_MINIMUM_DEPOSIT_WITHDRAWAL
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setMinimumDeposit(responseJson[ApiKey._API_DATA_KEY]?.minimumDeposit);
      setMinimumWithdrawal(
        responseJson[ApiKey._API_DATA_KEY]?.minimumWithdrawal
      );
    }
  };

  async function checkMemberRollover() {
    try {
      getLatestRolloverRecord();
      var rolloverJson = await ApiEngine.get(ApiUrl._API_CHECK_MEMBER_ROLLOVER);
      if (rolloverJson[ApiKey._API_SUCCESS_KEY]) {
        _dispatch(setDepositModal(true, 2));
      } else {
        setRolloverWarning(true);
      }
    } catch (err) {
      _dispatch(showResponseMessage(false, err));
    }
  }

  async function getLatestRolloverRecord() {
    var responseJson = await ApiEngine.get(
      ApiUrl._API_GET_LATEST_ROLLOVER_RECORD
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setRolloverData(responseJson[ApiKey._API_DATA_KEY]);
    }
  }

  const chunkArray = (arr, chunkSize) => {
    const result = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      result.push(arr.slice(i, i + chunkSize));
    }
    return result;
  };

  const itemsPerSlide = 2; // Two columns and three rows
  const slides = chunkArray(bigWinList, itemsPerSlide);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: slides.length > 2 ? 3 : 2, // Show one slide at a time
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: false,
    autoplay: true, // Enable auto-slide
    autoplaySpeed: 3000, // Time in milliseconds between slides
    arrows: false, // Hide navigation arrows
    responsive: [
      {

        breakpoint: 768,
        settings: {
          className: "",

          variableWidth: false,

          slidesToShow: 2,
          slidesToScroll: 1,
          vertical: true,
          verticalSwiping: false,
          autoplay: true, // Enable auto-slide
          autoplaySpeed: 3000, // Time in milliseconds between slides
          arrows: false, // Hide navigation arrows

        },
      }
    ]
  };



  return (
    <>
      {/* <Header /> */}

      {/* /////////t3 content */}
      <div className="t3-content-container mb-4">
        {showDepositSuccess && (
          <SweetAlert
            success
            confirmBtnText={t("CONFIRM")}
            onConfirm={() => {
              setShowDepositSuccess(false);
            }}
          >
            {t("DEPOSIT_SUCCESS")}
          </SweetAlert>
        )}

        {showDepositFailed && (
          <SweetAlert
            danger
            confirmBtnText={t("CONFIRM")}
            onConfirm={() => {
              setShowDepositFailed(false);
            }}
          >
            {t("DEPOSIT_FAILED")}
          </SweetAlert>
        )}

        {rolloverWarning && (
          <div className="sweet-alert-container t3-sweet-alert-container">
            <SweetAlert
              danger
              confirmBtnText={t("OK")}
              onConfirm={() => {
                setRolloverWarning(false);
              }}
            >
              <span className="sweet-alert-custom-text">
                {t("ROLLOVER_REQUIREMENT_ERROR")}
              </span>

              <div className="t3-rollover-sweet-alert mt-3">
                <div className="t3-rollover-sweet-alert-header">
                  <div className="first">{t("LATEST_ROLLOVER_PROGRESS")}</div>
                  <div className="second t3-rollover-box mt-3">
                    {rolloverData &&
                      rolloverData.length > 0 &&
                      map(rolloverData, (item, index) => {
                        const percentage =
                          (item["isLatestRecord"] == 1
                            ? item["rolloverProgress"]
                            : item["rolloverTarget"]) == 0 &&
                            item["rolloverTarget"] == 0
                            ? "0%"
                            : ((item["isLatestRecord"] == 1
                              ? item["rolloverProgress"]
                              : item["rolloverTarget"]) /
                              item["rolloverTarget"]) *
                            100;
                        return (
                          <div key={index} className="t3-rollover-sweet-alert-info">
                            <div className="t3-progress green">
                              <span className="t3-progress-left">
                                <span
                                  className="t3-progress-bar"
                                  style={{
                                    transform:
                                      percentage >= 50
                                        ? `rotate(180deg)`
                                        : `rotate(${(percentage / 100) * 180
                                        }deg)`,
                                  }}
                                ></span>
                              </span>
                              <span className="t3-progress-right">
                                <span
                                  className="t3-progress-bar"
                                  style={{
                                    transform:
                                      percentage > 50
                                        ? `rotate(${(percentage / 100) * -180
                                        }deg)`
                                        : `rotate(-0deg)`,
                                  }}
                                ></span>
                              </span>
                              <div className="t3-progress-value">
                                {percentage.toFixed(2)}
                                <span>%</span>
                              </div>
                            </div>

                            <div className="t3-rollover-sweet-alert-body">
                              <span className="title">
                                {/* {t("LATEST_TOP_UP")} :{" "} */}
                                {t("LATEST_TOP_UP_AND_BONUS")} :{" "}
                              </span>
                              <span className="amount">
                                {numberWithCurrencyFormat(
                                  parseFloat(item["amount"]),
                                  2,
                                  true
                                )}
                              </span>
                              <div className="date">{item["date"]}</div>
                              <div className="highlight-info">
                                {t("YOU_HAVE")}{" "}
                                <b>
                                  {item["isLatestRecord"] == 1
                                    ? item["rolloverProgress"]
                                    : item["rolloverTarget"]}{" "}
                                  / {item["rolloverTarget"]}
                                </b>{" "}
                                {t("TO_GO")}!
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>

              <div className="mt-3 mb-3 reminder-red">
                <div className="first">
                  <div className="first">
                    <img
                      src={require("../../assets/img/new_icons/red.png")}
                      className="img-responsive"
                      alt="icon"
                    />
                  </div>
                  <div>
                    <p className="text-left font14 mb-0 f-weight-500">
                      {t("ROLLOVER_RED_WARNING")}
                    </p>
                  </div>
                </div>
              </div>
            </SweetAlert>
          </div>
        )}

        <div className={openMenu ? "left active" : "left"}></div>
        <div className={openMenu ? "right active" : "right"}>
          <div className="t3-dashboard-slick">
            {!bannerLoading ? (
              <Swiper
                className="t3-dashboard-banner"
                spaceBetween={0}
                slidesPerView={3}
                onSlideChange={() => {
                  // console.log('slide change')
                }}
                onSwiper={(swiper) => {
                  // console.log(swiper)
                }}
                loop={bannersList.length > 3}
                autoplay={{
                  delay: 4000,
                  disableOnInteraction: false,
                  pauseOnMouseEnter: true,
                }}
                breakpoints={{
                  1280: {
                    slidesPerView: 3,
                    spaceBetween: 0,
                  },
                  1024: {
                    slidesPerView: 3,
                    spaceBetween: 0,
                  },
                  480: {
                    slidesPerView: 2,
                    spaceBetween: 0,
                  },
                  0: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                  },
                }}
              >
                {bannersList &&
                  bannersList.length > 0 ? (
                  // Swiper v9.4.1+ => Because of nature of how the loop mode works (it will rearrange slides), total number of slides must be >= slidesPerView * 2
                  Array.from({ length: Math.ceil(bannersList.length / 3) }).map((_, i) => (
                    map(bannersList, (item, index) => {
                      return (
                        <SwiperSlide key={index}>
                          <div
                            className="banner-items"
                            onClick={(e) => {
                              if (item.url && item.url == "/deposit") {
                                if (heartBeat) {
                                  _dispatch(setDepositModal(true));
                                } else {

                                  _dispatch(setLoginModal(true));
                                
                                }
                              } else {
                                window.location.href = `${process.env.REACT_APP_ENDPOINT_URL || ""
                                  }${item.url}`;
                              }
                              // if (heartBeat) {
                              //   if (item.url && item.url == "/deposit") {
                              //     _dispatch(setDepositModal(true));
                              //   } else {
                              //     window.location.href = `${process.env.REACT_APP_ENDPOINT_URL || ""
                              //       }${item.url}`;
                              //   }
                              // } else {
                              //   if (item.url == "/deposit") {
                              //     _dispatch(
                              //       showMessage({
                              //         custom: true,
                              //         customIcon: (
                              //           <img
                              //             className="m-auto"
                              //             src={alertIcon}
                              //             height="120px"
                              //             width="120px"
                              //             alt="Custom Icon"
                              //           />
                              //         ),
                              //         content: t("PLEASE_LOGIN_FIRST"),
                              //       })
                              //     );
                              //   }
                              // }
                            }}
                          >
                            <img
                              src={item.mobileImage}
                              alt="banner"
                              className="img-100"
                              loading="lazy"
                            />
                          </div>
                        </SwiperSlide>
                      );
                    })
                  ))
                ) : (
                  null
                  // <div style={{ display: "flex" }}>
                  //   <Skeleton height={200} baseColor="#333" highlightColor="#636363" />
                  //   <Skeleton className="hide-767" height={200} baseColor="#333" highlightColor="#636363" />
                  //   <Skeleton className="hide-767" height={200} baseColor="#333" highlightColor="#636363" />
                  // </div>
                )}
              </Swiper>
            ) : (
              <div style={{ display: "flex", flexDirection: "row", width: "100%", gap: "15px" }}>
                <div style={{ width: "100%" }}>
                  <Skeleton height={200} baseColor="#333" highlightColor="#636363" />
                </div>
                <div className="hide-767" style={{ width: "100%" }}>
                  <Skeleton height={200} baseColor="#333" highlightColor="#636363" />
                </div>
                <div className="hide-767" style={{ width: "100%" }}>
                  <Skeleton height={200} baseColor="#333" highlightColor="#636363" />
                </div>
              </div>
            )}
          </div>
          <div className="t3-announcement-container mt-3">
            <div className="first hide-767">
              {/* <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACUAAAAiCAMAAADvReCJAAAACGFjVEwAAAADAAAAAM7tusAAAABFUExURQAAAM/vz8fn18Xk1cfj08bi0sXi0sTh0cXh0cTi0sXj0cXi0sTi0sXi0sTi0sXi0sXh0cTi0sXh0cXh0cTh0cXh0cXi0hDGZVYAAAAWdFJOUwAQIDBAUGBwgI+Qn6Cwv8DP0N/g7/BQ+mYHAAAAGmZjVEwAAAAAAAAAJQAAACIAAAAAAAAAAAABAAQAAAa1MssAAABiSURBVDjL7dQ7DoAgFAVR8AU1avwBd/9LlQJjYXQSa6c+9biP2ZoaRknqCbUSq0Gs/ChWfhUr28UqJD2rdq5JL0pnv/pVrSN156xcyKxKFlmV/AKqNqHiA1xZZlVqtmjuUwd0XiRp0nIYuQAAABpmY1RMAAAAAQAAABAAAAAVAAAADgAAAAQAAQAEAAEQEonzAAAAWGZkQVQAAAACOMu1jkEOgCAMBKlS1EQFlMz/n2q8sRy82eO03dmwhT/HzisKcGDpwXQDLj8HNBNSIUlMgqwiKKoGvkEcM1bYpVrTZlag9gcFmEUx9vT87h87pQQ2dmgqxgAAABpmY1RMAAAAAwAAAAkAAAAaAAAAHAAAAAQAAQAEAAGSl0JaAAAAaGZkQVQAAAAEOMt1ztkKgDAQQ9GObd1wxXr//1PVBudBsFA4kAkkxGWwUN8Mm5hxtsAYnElcYZEaoPF+55ezZFCe6P4nmLfTV5P0kwJCht0L2mUFYtX4hhHIFQXWBwk4Tds4dB76SYMue7MG2OVprN4AAAAcdEVYdFNvZnR3YXJlAEFQTkcgQXNzZW1ibGVyIDIuOTH+/Sr4AAAAAElFTkSuQmCC"
                className="img-responsive"
                alt="announcement"
              /> */}
              <SpeakerIcon />
            </div>
            <div className="second">
              <marquee>
                <span className="text">
                  {!stringIsNullOrEmpty(content) && parse(content)}
                </span>
              </marquee>
            </div>
          </div>
          <div className="show-767 mt-3">
            {heartBeat == false && (
              <>
                <div className="home-login-register-box">
                  <div
                    onClick={() => {
                      _dispatch(setLoginModal(true));
                    }}
                  >
                    {t("LOGIN")}
                  </div>
                  <div
                    onClick={() => {
                      _dispatch(setRegisterModal(true));
                    }}
                  >
                    {t("REGISTER")}
                  </div>
                </div>
              </>
            )}
            {heartBeat && (
              <div className="mobile-home-balance-box mt-3">
                {heartBeat && (
                  <div className="head">
                    <div className="title">{memberData["username"]}</div>
                    <div className="level">
                      { t("MEMBERSHIP")}: {t(_userData.vipName.toUpperCase())}
                      <img
                        className="img-responsive"
                        src={memberData["membership"]?.["icon"]}
                      />
                    </div>
                  </div>
                )}
                <div className="body">
                  <div className="first">
                    <div>{t("BALANCE")}:</div>
                    <div className="amount">
                      {numberWithCurrencyFormat(
                        walletsDetails.currentWalletBalance +
                        walletsDetails.bonusWalletBalance,
                        2,
                        true
                      )}
                    </div>
                    <div>
                      {t("MIN_DEPOSIT")}: <span>{minimumDeposit.toFixed(2)}</span>
                    </div>
                    <div>
                      {t("MIN_WITHDRAWAL")}:{" "}
                      <span>{minimumWithdrawal.toFixed(2)}</span>
                    </div>
                  </div>
                  <div className="second">
                    <button
                      onClick={() => {
                        if (heartBeat) {
                          // _history.push(WebUrl._URL_DEPOSIT)
                          _dispatch(setDepositModal(true));
                        } else {
                          _dispatch(setLoginModal(true));
                        }
                      }}
                    >
                      <VIcon
                        icon={themeIcons.deposit}
                        className="theme-icon-size-20"
                      />
                      {t("DEPOSIT")}
                    </button>
                    <button
                      onClick={() => {
                        if (heartBeat) {
                          checkMemberRollover();
                        } else {
                          _dispatch(setLoginModal(true));
                        }
                      }}
                    >
                      <VIcon
                        icon={themeIcons.withdraw}
                        className="theme-icon-size-20"
                      />
                      {t("WITHDRAW")}
                    </button>
                    <button
                      onClick={() => {
                        if (heartBeat) {
                          getRestore();
                        } else {
                          _dispatch(setLoginModal(true));
                        }
                      }}
                    >
                      {balanceRefresh ? (
                        <Spinner
                          className="dynamic-table-spinner"
                          animation="border"
                          role="status"
                          size="sm"
                          style={{
                            verticalAlign: "middle",
                            width: "0.875rem",
                            height: "0.875rem",
                            marginLeft: 3,
                          }}
                        ></Spinner>
                      ) : (
                        <VIcon
                          icon={themeIcons.refresh}
                          className="theme-icon-size-20"
                        />
                      )}
                      {t("REFRESH")}
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="">
            <div className="mt-4">
              <h3 className="t3-custom-title mb-2">
                <span>{t("LIVE")} {t("TRANSACTION_UPPER")}</span>
              </h3>
              <div className="mobile-latest-deposit-withdraw-box">
                <div>
                  <div className="mobile-home-latest-transactions-box">
                    <div className="head">
                      <div>{t("DEPOSIT")}</div>
                    </div>
                    
                    {transLoading ?  
                      <div className="body empty-data">
                        <Spinner
                            className="dynamic-table-spinner"
                            animation="border"
                            role="status"
                            size="sm"
                            style={{
                              verticalAlign: "middle",
                              width: "3rem",
                              height: "3rem",
                              margin: "2rem"
                            }}
                          ></Spinner>
                      </div> : 
                      latestDepositList && latestDepositList.length > 0 ? (
                      map(latestDepositList, (item, index) => {
                        return (
                          <div key={index} className={`body ${index % 2 === 0 ? 'even' : 'odd'}`}>
                            <div className="latest-label">
                              <img src={item.icon} />
                              {item.username}
                            </div>
                            <div>{item.amount.toFixed(2)}</div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="body empty-data">
                        {noDataBg ?
                          <><img
                            src={noDataBg}
                            className="img-responsive"
                            width={"60px"}
                            alt="icon"
                          /><p className="m-0">{t("NO_DATA_FOUND")}</p></> : <Spinner
                            className="dynamic-table-spinner"
                            animation="border"
                            role="status"
                            size="sm"
                            style={{
                              verticalAlign: "middle",
                              width: "3rem",
                              height: "3rem",
                              margin: "2rem"
                            }}
                          ></Spinner>
                        }
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  <div className="mobile-home-latest-transactions-box">
                    <div className="head">
                      <div>{t("WITHDRAWAL")}</div>
                    </div>
                    {transLoading ?  
                      <div className="body empty-data">
                        <Spinner
                            className="dynamic-table-spinner"
                            animation="border"
                            role="status"
                            size="sm"
                            style={{
                              verticalAlign: "middle",
                              width: "3rem",
                              height: "3rem",
                              margin: "2rem"
                            }}
                          ></Spinner>
                      </div> : 
                      latestWithdrawalList && latestWithdrawalList.length > 0 ? (
                      map(latestWithdrawalList, (item, index) => {
                        return (
                          <div key={index} className={`body ${index % 2 === 0 ? 'even' : 'odd'}`}>
                            <div className="latest-label">
                              <img src={item.icon} />
                              {item.username}
                            </div>
                            <div>{item.amount.toFixed(2)}</div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="body empty-data">
                        {noDataBg ?
                          <><img
                            src={noDataBg}
                            className="img-responsive"
                            width={"60px"}
                            alt="icon"
                          /><p className="m-0">{t("NO_DATA_FOUND")}</p></> : <Spinner
                            className="dynamic-table-spinner"
                            animation="border"
                            role="status"
                            size="sm"
                            style={{
                              verticalAlign: "middle",
                              width: "3rem",
                              height: "3rem",
                              margin: "2rem"
                            }}
                          ></Spinner>
                        }
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

              {initFlag ? (
                <>
                  {bigWinList && bigWinList.length > 0 && (
                    <div className="bigwin payout-container mt-4">
                      <div>
                        <h3 className="t3-custom-second-title mb-2">
                          <img className="gif" src={require("../../assets/img/trophy-gif.gif")} />{t("RECENT")} <span>{t("BIG_WINS")}</span>
                        </h3>
                        <div className="payout-list">
                          <Slider {...settings}>
                            {slides.map((slide, index) => (
                              <div key={index} className="slider-slide">
                                {slide.map((item, index) => (
                                  <div key={index} className="slider-item">
                                    <FallBackImage
                                      src={item.image}
                                      alt={item.productName}
                                      fallbackSrc={defaultGameIcon}
                                    />
                                  
                                    <div className="slider-item-item">
                                      <div>
                                        <div className="title">{t("PROVIDER")}: {item.productName}</div>
                                        <div className="username">{item.username} {t("in")} <span>{item.gameName}</span> {t("won")}</div>
                                        <div className="amount">{companyCurrencySymbol}{item.winning.toFixed(2)}</div>
                                      </div>
                                      <div className="play-button" onClick={() => {
                                        if (heartBeat) {
                                          setPopupBannerImage(item["popupBannerImage"]);
                                          if (item["gameUm"] !== true) {
                                            startGame(
                                              item["id"],
                                              item["hasOwnLobby"],
                                              item["masterProductId"],
                                              item["gameName"],
                                              item["masterProductIsSeamless"],
                                              item["isApp"],
                                              item["appUrl"],
                                              item["appDeepLink"],
                                              item["isNewWindow"],
                                              item["packageNameKeyword"]
                                            );
                                          }
                                        } else {
                                          _dispatch(setLoginModal(true));
    
                                        
                                        }
                                      }}></div>
                                    </div>
                                    
                                  </div>
                                ))}
                              </div>
                            ))}
                          </Slider>  
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <Skeleton className="mt-4" height={400} baseColor="#333" highlightColor="#636363" />
              )
            }


            {heartBeat && (
              <>
                <div className="t3-custom-title">
                  &nbsp;
                </div>
                {showCodeAlert && (
                  <div className="mt-3 mb-3 reminder-green">
                    <div className="first">
                      <div className="first">
                        <img
                          src={require("../../assets/img/new_icons/green.png")}
                          className="img-responsive"
                          alt="icon"
                        />
                      </div>
                      <div>
                        <p className="font14 mb-0 f-weight-500">
                          {t("COPY_SUCCESS")}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
                <div className="home-referral-container">
                  <div className="t3-team-banner-img-bg">
                    {referralBanner ?

                      <img
                        src={referralBanner}
                        className="img-teamBanner hide-1250"
                        alt="referral"
                      />
                      :
                      <img
                        src={require("../../assets/img/template_1/referral-banner.jpg")}
                        className="img-teamBanner hide-1250"
                        alt="referral"
                      />
                    }
                    {referralBannerMobile ?

                      <img
                        src={referralBannerMobile}
                        className="w-100 show-1250"
                        alt="referral"
                      />
                      :
                      <img
                        src={require("../../assets/img/template_1/referral-banner-mobile.jpg")}
                        className="w-100 show-1250"
                        alt="referral"
                      />
                    }
                    <div className="t3-team-banner-code-box">
                      <div className="hide-767">
                        <div className="t3-team-banner-title">
                          {t("MY_REFERRAL_CODE")}
                        </div>
                        <div className="t3-team-banner-details">
                          <div className="first" ref={_codeRef}>
                            {/* {referralDetails} */}
                            {uplineLoading ? (
                              <Spinner
                                className="dynamic-table-spinner"
                                animation="border"
                                role="status"
                                size="sm"
                                style={{
                                  verticalAlign: "middle",
                                  width: "1rem",
                                  height: "1rem",
                                }}
                              ></Spinner>
                            ) : (
                              referralDetails
                            )}
                          </div>
                          <div className="second cursor-pointer">
                            {copy1 ? t("COPIED") : (<VIcon
                              icon={themeIcons.copy}
                              onClick={() => copyItem(_codeRef)}
                              className="vicon"
                            />)}
                          </div>
                        </div>
                      </div>
                      <div className="mt-3 hide-767">
                        <div className="t3-team-banner-title">
                          {t("MY_REFERRAL_LINK")}
                        </div>
                        <div className="t3-team-banner-details">
                          <div className="first" ref={_linkRef}>
                            {uplineLoading ? (
                              <Spinner
                                className="dynamic-table-spinner"
                                animation="border"
                                role="status"
                                size="sm"
                                style={{
                                  verticalAlign: "middle",
                                  width: "1rem",
                                  height: "1rem",
                                }}
                              ></Spinner>
                            ) : (
                              registerUrl
                            )}
                          </div>
                          <div className="second cursor-pointer">
                            {copy2 ? t("COPIED") : (<VIcon
                              icon={themeIcons.copy}
                              onClick={() => copyItem2(_linkRef)}
                              className="vicon"
                            />)}
                          </div>
                        </div>
                      </div>

                      <div className="home-referral-button-group-box mt-3">
                        <div
                          className="t3-team-copy-btn"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            if (heartBeat) {
                              _dispatch(setShareReferralModal(true));
                            } else {
                              _dispatch(setLoginModal(true));
                            }
                          }}
                        >
                          {t("SHARE")}
                        </div>
                        <div
                          className="t3-team-copy-btn"
                          onClick={() => {
                            if (heartBeat) {
                              _dispatch(setDownlineModal(true));
                            } else {
                              _dispatch(setLoginModal(true));
                            }
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          {t("DOWNLINE")}
                        </div>
                      </div>
                      <div
                        className="t3-team-copy-btn"
                        onClick={() => {
                          if (heartBeat) {
                            _history.push(WebUrl._URL_REFERRAL);
                          } else {
                            _dispatch(setLoginModal(true));
                          }
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        {t("MORE_INFO")}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="t3-game-category-box mt-4 mb-4">
            <div className="t3-game-category-list">
              {gameCategories &&
                gameCategories.length > 0 &&
                map(gameCategories, (item, index) => {
                  return (
                    <div
                      key={index}
                      className={
                        "t3-game-category-item" +
                        (gameCategoryActive.id == item.id ? " active" : "")
                      }
                      onClick={() =>
                        setGameCategoryActive({
                          id: item.id,
                          title: item.title,
                        })
                      }
                    >
                      <div className="first">
                        <img
                          src={item.image}
                          className="img-responsive"
                          alt={item.title}
                          loading="lazy"
                        />
                      </div>
                      <h6>{t(item.title)}</h6>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="t3-game-list-container">
            <div className="t3-category-game-list-container">
              {/* <NetflixLoader/>  */}
              {games.length == 0 && <LoadingGames />}
              <div className="t3-game-list-box">
                {gameCategoryActive.title != "All"
                  ? games.map((game, index) => {
                    if (game["categoryTitle"] === gameCategoryActive.title) {
                      return (
                        <div key={index}>
                          {game["GameUM"]}
                          <div
                            className="t3-game-list-item"
                            onClick={() => {
                              if (heartBeat) {
                                setPopupBannerImage(game["popupBannerImage"]);
                                if (game["gameUm"] !== true) {
                                  startGame(
                                    game["id"],
                                    game["hasOwnLobby"],
                                    game["masterProductId"],
                                    game["gameName"],
                                    game["masterProductIsSeamless"],
                                    game["isApp"],
                                    game["appUrl"],
                                    game["appDeepLink"],
                                    game["isNewWindow"],
                                    game["packageNameKeyword"]
                                  );
                                  /* if (walletsDetails.isValidForPromotion) {
                                      _dispatch(
                                        showMessage({
                                          custom: true,
                                          customIcon: <img className="m-auto" src={alertIcon} height="120px" width="120px" alt="Custom Icon" />,
                                          content: t("PLEASE_CLAIM_PROMO_BEFORE_ENTER_GAME"),
                                          showCancel: true,
                                          onConfirm: () => {
                                            setTransactionValidForPromo();
                                            startGame(
                                              game["id"],
                                              game["hasOwnLobby"],
                                              game["masterProductId"],
                                              game["gameName"],
                                              game["masterProductIsSeamless"],
                                              game["isApp"],
                                              game["appUrl"],
                                              game["appDeepLink"],
                                              game["isNewWindow"],
                                              game["packageNameKeyword"]
                                            );
                                          }
                                        })
                                      );
                                    } else {
                                      startGame(
                                        game["id"],
                                        game["hasOwnLobby"],
                                        game["masterProductId"],
                                        game["gameName"],
                                        game["masterProductIsSeamless"],
                                        game["isApp"],
                                        game["appUrl"],
                                        game["appDeepLink"],
                                        game["isNewWindow"],
                                        game["packageNameKeyword"]
                                      );
                                    } */
                                }
                              } else {
                                _dispatch(setLoginModal(true));

                               
                              }
                            }}
                          >
                            <div className="t3-game-list-image-box">
                              <div className="image">
                                <div className="first">
                                  <FallBackImage
                                    src={game["image"]}
                                    alt={game["gameName"]}
                                    fallbackSrc={defaultGameIcon}
                                  />
                                </div>
                                <div className="second">
                                  <svg
                                    className="play-icon"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 63 63"
                                  >
                                    <defs></defs>
                                    <g fill="none" fillRule="evenodd">
                                      <circle
                                        cx="31.3"
                                        cy="31.3"
                                        r="30.3"
                                        fill="currentColor"
                                        fillOpacity=".2"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                      ></circle>
                                      <path
                                        fill="currentColor"
                                        d="M39.5 34.3l-11.3 7.5a2 2 0 01-3-1.6v-15a2 2 0 013-1.7L39.5 31a2 2 0 010 3.3z"
                                      ></path>
                                    </g>
                                  </svg>
                                </div>
                              </div>
                            </div>
                            <div className="t3-game-list-text-box">
                              <h6>{game["gameName"]}</h6>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })
                  : games.map((game, index) => {
                    return (
                      <div
                        key={index}
                        className="t3-game-list-item"
                        onClick={() => {
                          if (heartBeat) {
                            setPopupBannerImage(game["popupBannerImage"]);
                            if (game["gameUm"] !== true) {
                              startGame(
                                game["id"],
                                game["hasOwnLobby"],
                                game["masterProductId"],
                                game["gameName"],
                                game["masterProductIsSeamless"],
                                game["isApp"],
                                game["appUrl"],
                                game["appDeepLink"],
                                game["isNewWindow"],
                                game["packageNameKeyword"]
                              );
                              /* if (walletsDetails.isValidForPromotion) {
                                _dispatch(
                                  showMessage({
                                    custom: true,
                                    customIcon: <img className="m-auto" src={alertIcon} height="120px" width="120px" alt="Custom Icon" />,
                                    content: t("PLEASE_CLAIM_PROMO_BEFORE_ENTER_GAME"),
                                    showCancel: true,
                                    onConfirm: () => {
                                      setTransactionValidForPromo();
                                      startGame(
                                        game["id"],
                                        game["hasOwnLobby"],
                                        game["masterProductId"],
                                        game["gameName"],
                                        game["masterProductIsSeamless"],
                                        game["isApp"],
                                        game["appUrl"],
                                        game["appDeepLink"],
                                        game["isNewWindow"],
                                        game["packageNameKeyword"]
                                      );
                                    }
                                  })
                                );
                              } else {
                                startGame(
                                  game["id"],
                                  game["hasOwnLobby"],
                                  game["masterProductId"],
                                  game["gameName"],
                                  game["masterProductIsSeamless"],
                                  game["isApp"],
                                  game["appUrl"],
                                  game["appDeepLink"],
                                  game["isNewWindow"],
                                  game["packageNameKeyword"]
                                );
                              } */
                            }
                          } else {
                            _dispatch(setLoginModal(true));
                          }
                        }}
                      >
                        <div className="t3-game-list-image-box">
                          <div className="image">
                            <div className="first">
                              <FallBackImage
                                src={game["image"]}
                                alt={game["gameName"]}
                                fallbackSrc={defaultGameIcon}
                              />
                              {game["gameUm"] == true && (
                                <>
                                  <div
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                    }}
                                    className="v2-um-tag game-category-label"
                                  ></div>
                                  <div

                                    className="v2-um-tag-icon"
                                  >
                                    <svg className="icon-repair" width="150" height="150" viewBox="0 0 150 150" xmlns="http://www.w3.org/2000/svg">
                                      <g clipPath="url(#clip0_1031_6568)">
                                        <path d="M148.878 18.7427C148.334 16.9377 146.058 16.3699 144.73 17.6977L136.061 26.3672H123.633V13.9389L132.303 5.26523C133.637 3.93047 133.058 1.6626 131.267 1.11797C128.813 0.372363 126.267 0.0541992 123.658 0.0541992C116.59 0.0541992 109.964 2.73779 104.99 7.72471C98.896 13.8144 96.1667 22.522 97.6772 31.0233L98.0806 33.2763L33.276 98.0807C30.6583 97.6128 29.0818 97.2694 26.4009 97.2694C19.4399 97.2694 12.6293 100.085 7.7197 104.99C0.790989 111.923 -1.65091 122.075 1.11648 131.257C1.66199 133.066 3.93367 133.633 5.26433 132.302L13.9344 123.633H26.4258V136.066L17.6953 144.737C16.362 146.069 16.9371 148.341 18.7333 148.886C21.1816 149.626 23.7243 150 26.3282 150C42.7409 150 55.2035 135.192 52.3221 118.976L51.9187 116.723L116.719 51.919C119.293 52.379 120.936 52.7332 123.594 52.7259C130.568 52.7259 137.374 49.9148 142.275 45.0097C149.209 38.0798 151.647 27.9305 148.878 18.7427Z" />
                                        <path d="M143.576 112.489L112.755 81.9729C110.699 79.9204 108.143 78.3135 105.343 77.1926L76.9005 105.635C78.0211 108.436 79.6295 110.994 81.6841 113.052L112.501 143.564C121.066 152.13 134.97 152.161 143.576 143.564C152.142 134.994 152.142 121.055 143.576 112.489ZM131.049 131.037C129.332 132.754 126.551 132.754 124.835 131.037L103.241 109.748C101.524 108.031 101.524 105.25 103.241 103.534C104.957 101.817 107.738 101.817 109.455 103.534L131.049 124.823C132.765 126.54 132.765 129.32 131.049 131.037Z" />
                                        <path d="M37.5822 25.1538L37.5757 25.1558L41.539 21.1925C42.6877 20.0426 42.4463 18.1251 41.0583 17.2925L14.2299 0.62641C12.5011 -0.410992 10.2878 -0.138531 8.86246 1.28735L1.28746 8.86235C-0.139008 10.2888 -0.410883 12.5031 0.627692 14.2322L17.3052 41.0476C18.1408 42.4374 20.0632 42.6692 21.2046 41.5266L25.1518 37.58L53.2507 65.6789L65.6791 53.2506L37.5822 25.1538Z" />
                                      </g>
                                      <defs>
                                        <clipPath id="clip0_1031_6568">
                                          <rect width="150" height="150" fill="white" />
                                        </clipPath>
                                      </defs>
                                    </svg>
                                  </div>
                                </>
                              )}
                            </div>
                            {game["gameUm"] == false && (
                              <div className="second">
                                <svg
                                  className="play-icon"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 63 63"
                                >
                                  <defs></defs>
                                  <g fill="none" fillRule="evenodd">
                                    <circle
                                      cx="31.3"
                                      cy="31.3"
                                      r="30.3"
                                      fill="currentColor"
                                      fillOpacity=".2"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                    ></circle>
                                    <path
                                      fill="currentColor"
                                      d="M39.5 34.3l-11.3 7.5a2 2 0 01-3-1.6v-15a2 2 0 013-1.7L39.5 31a2 2 0 010 3.3z"
                                    ></path>
                                  </g>
                                </svg>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="t3-game-list-text-box">
                          <h6>{game["gameName"]}</h6>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>

          {
            payoutList.length > 0 && (
              <div className="payout-container mt-4">
                <div>
                  <h3 className="t3-custom-second-title mb-2">
                    <img className="gif" src={require("../../assets/img/trophy-gif.gif")} /><span>{t("RECENT")}</span> {t("PAYOUT")}
                  </h3>
                  <div className="payout-list">
                    <Slider {...payoutSlick}>
                      {
                        map(payoutList, (item, index) => {
                          return (
                            <div key={index}>
                              <div className="item">
                                <div className="payout-list-image">
                                  <FallBackImage
                                    src={item.image}
                                    alt={item.username}
                                    fallbackSrc={defaultGameIcon}
                                  />
                                </div>
                                {/* <img src={item.image} /> */}
                                <div className="payout-list-details">
                                  <div className="title">{item.game}</div>
                                  <div className="username">{item.username}</div>
                                  <div className="amount">{companyCurrencySymbol}{item.winning.toFixed(2)}</div>
                                </div>

                              </div>

                            </div>
                          )
                        })
                      }

                    </Slider>

                  </div>
                </div>
              </div>
            )
          }

          <div className="t3-game-list-container mt-5">
            <div className="t3-game-list-title-with-arrows-box mb-4">
              <div className="first">
                <h3 className="t3-custom-title">
                  <span>{t("RECOMMENDED")}</span> {t("SLOTS").toUpperCase()}
                </h3>
              </div>
              <div className="second">
                <div className="t3-game-item-count-box" style={{ display: "none" }}>
                  {t("ALL")} <span>{recommendedSlotGamesCount}</span>{" "}
                  {t("SLOTS")}
                </div>
                {slotSlickRef?.current?.innerSlider.props.children.length > 1 && <>
                  <div className="t3-custom-slick-arrow">
                    <div onClick={() => slotSlickRef?.current?.slickPrev()}>
                      <VIcon
                        icon={themeIcons.angleLeft}
                        className="theme-icon-size-20"
                      />
                    </div>
                    <div onClick={() => slotSlickRef?.current?.slickNext()}>
                      <VIcon
                        icon={themeIcons.angleRight}
                        className="theme-icon-size-20"
                      />
                    </div>
                  </div>
                </>
                }
              </div>
            </div>

            <div className="t3-slot-games-box">
            {recommendedSlotGames.length == 0 && <LoadingGames />}
              <Slider ref={slotSlickRef} {...slotSlick} className="">
                {recommendedSlotGames &&
                  recommendedSlotGames.length &&
                  map(recommendedSlotGames, (item, index) => {
                    return (
                      <div key={index} className="t3-game-list-box">
                        {item &&
                          item.length &&
                          map(item, (game, index2) => {
                            return (
                              <div
                                key={index2}
                                className="t3-game-list-item"
                                onClick={(e) => {
                                  if (dragging) {
                                    e.preventDefault();
                                  } else {
                                    if (heartBeat) {
                                      setPopupBannerImage(
                                        game["popupBannerImage"]
                                      );
                                      if (game["gameUm"] !== true) {
                                        if (
                                          walletsDetails.isValidForPromotion
                                        ) {
                                          _dispatch(
                                            showMessage({
                                              custom: true,
                                              customIcon: (
                                                <img
                                                  className="m-auto"
                                                  src={alertIcon}
                                                  height="120px"
                                                  width="120px"
                                                  alt="Custom Icon"
                                                />
                                              ),
                                              content: t(
                                                "PLEASE_CLAIM_PROMO_BEFORE_ENTER_GAME"
                                              ),
                                              showCancel: true,
                                              onConfirm: () => {
                                                setTransactionValidForPromo();
                                                startGame(
                                                  game["id"],
                                                  game["hasOwnLobby"],
                                                  game["masterProductId"],
                                                  game["gameName"],
                                                  game[
                                                  "masterProductIsSeamless"
                                                  ],
                                                  game["isApp"],
                                                  game["appUrl"],
                                                  game["appDeepLink"],
                                                  game["isNewWindow"],
                                                  game["packageNameKeyword"]
                                                );
                                              },
                                            })
                                          );
                                        } else {
                                          startGame(
                                            game["id"],
                                            game["hasOwnLobby"],
                                            game["masterProductId"],
                                            game["gameName"],
                                            game["masterProductIsSeamless"],
                                            game["isApp"],
                                            game["appUrl"],
                                            game["appDeepLink"],
                                            game["isNewWindow"],
                                            game["packageNameKeyword"]
                                          );
                                        }
                                      }
                                    } else {
                                      _dispatch(setLoginModal(true));
                                    }
                                  }
                                }}
                              >
                                <div className="t3-game-list-image-box">
                                  <div className="image">
                                    <div className="first">
                                      {!isEmpty(game.iconImage) ? (
                                        <img
                                          className="header-provider"
                                          src={game.iconImage}
                                          alt={game.gameName}
                                          loading="lazy"
                                        />
                                      ) : (
                                        ""
                                      )}

                                      <FallBackImage
                                        src={game.image}
                                        alt={game.gameName}
                                        fallbackSrc={defaultGameIcon}
                                      />
                                    </div>
                                    <div className="second">
                                      <svg
                                        className="play-icon"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 63 63"
                                      >
                                        <defs></defs>
                                        <g fill="none" fillRule="evenodd">
                                          <circle
                                            cx="31.3"
                                            cy="31.3"
                                            r="30.3"
                                            fill="currentColor"
                                            fillOpacity=".2"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                          ></circle>
                                          <path
                                            fill="currentColor"
                                            d="M39.5 34.3l-11.3 7.5a2 2 0 01-3-1.6v-15a2 2 0 013-1.7L39.5 31a2 2 0 010 3.3z"
                                          ></path>
                                        </g>
                                      </svg>
                                    </div>
                                  </div>
                                </div>
                                <div className="t3-game-list-text-box">
                                  <h6>{game.gameName}</h6>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    );
                  })}
              </Slider>
            </div>
          </div>

          <div className="t3-game-list-container mt-5">
            <div className="t3-game-list-title-with-arrows-box mb-4">
              <div className="first">
                <h3 className="t3-custom-title">
                  <span>{t("FEATURE_BUY_IN")}</span> {t("SLOTS").toUpperCase()}
                </h3>
              </div>
              <div className="second">
                <div className="t3-game-item-count-box" style={{ display: "none" }}>
                  {t("ALL")} <span>{featuredSlotGamesCount}</span> {t("SLOTS")}
                </div>
                {featuredSlickRef?.current?.innerSlider.props.children.length > 1 && <>
                  <div className="t3-custom-slick-arrow">
                    <div onClick={() => featuredSlickRef?.current?.slickPrev()}>
                      <VIcon
                        icon={themeIcons.angleLeft}
                        className="theme-icon-size-20"
                      />
                    </div>
                    <div onClick={() => featuredSlickRef?.current?.slickNext()}>
                      <VIcon
                        icon={themeIcons.angleRight}
                        className="theme-icon-size-20"
                      />
                    </div>
                  </div>
                </>
                }
              </div>
            </div>
            <div className="t3-slot-games-box">
              {featuredSlotGames && featuredSlotGames.length == 0 && (
                <LoadingGames />
              )}
              <Slider ref={featuredSlickRef} {...slotSlick} className="">
                {featuredSlotGames &&
                  featuredSlotGames.length &&
                  map(featuredSlotGames, (item, index) => {
                    return (
                      <div key={index} className="t3-game-list-box">
                        {item &&
                          item.length &&
                          map(item, (game, index2) => {
                            return (
                              <div
                                key={index2}
                                className="t3-game-list-item"
                                onClick={(e) => {
                                  if (dragging) {
                                    e.preventDefault();
                                  } else {
                                    if (heartBeat) {
                                      setPopupBannerImage(
                                        game["popupBannerImage"]
                                      );
                                      if (game["gameUm"] !== true) {
                                        if (
                                          walletsDetails.isValidForPromotion
                                        ) {
                                          _dispatch(
                                            showMessage({
                                              custom: true,
                                              customIcon: (
                                                <img
                                                  className="m-auto"
                                                  src={alertIcon}
                                                  height="120px"
                                                  width="120px"
                                                  alt="Custom Icon"
                                                />
                                              ),
                                              content: t(
                                                "PLEASE_CLAIM_PROMO_BEFORE_ENTER_GAME"
                                              ),
                                              showCancel: true,
                                              onConfirm: () => {
                                                setTransactionValidForPromo();
                                                startGame(
                                                  game["id"],
                                                  game["hasOwnLobby"],
                                                  game["masterProductId"],
                                                  game["gameName"],
                                                  game[
                                                  "masterProductIsSeamless"
                                                  ],
                                                  game["isApp"],
                                                  game["appUrl"],
                                                  game["appDeepLink"],
                                                  game["isNewWindow"],
                                                  game["packageNameKeyword"]
                                                );
                                              },
                                            })
                                          );
                                        } else {
                                          startGame(
                                            game["id"],
                                            game["hasOwnLobby"],
                                            game["masterProductId"],
                                            game["gameName"],
                                            game["masterProductIsSeamless"],
                                            game["isApp"],
                                            game["appUrl"],
                                            game["appDeepLink"],
                                            game["isNewWindow"],
                                            game["packageNameKeyword"]
                                          );
                                        }
                                      }
                                    } else {
                                      _dispatch(setLoginModal(true));
                                    }
                                  }
                                }}
                              >
                                <div className="t3-game-list-image-box">
                                  <div className="image">
                                    <div className="first">
                                      <FallBackImage
                                        src={game.image}
                                        alt={game.gameName}
                                        fallbackSrc={defaultGameIcon}
                                      />
                                    </div>
                                    <div className="second">
                                      <svg
                                        className="play-icon"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 63 63"
                                      >
                                        <defs></defs>
                                        <g fill="none" fillRule="evenodd">
                                          <circle
                                            cx="31.3"
                                            cy="31.3"
                                            r="30.3"
                                            fill="currentColor"
                                            fillOpacity=".2"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                          ></circle>
                                          <path
                                            fill="currentColor"
                                            d="M39.5 34.3l-11.3 7.5a2 2 0 01-3-1.6v-15a2 2 0 013-1.7L39.5 31a2 2 0 010 3.3z"
                                          ></path>
                                        </g>
                                      </svg>
                                    </div>
                                  </div>
                                </div>
                                <div className="t3-game-list-text-box">
                                  <h6>{game["gameName"]}</h6>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    );
                  })}
              </Slider>
            </div>
          </div>

          <div className="t3-providers-container mt-5">
            <h3 className="t3-custom-title mb-3"><span>{t("HOT")}</span> {t("PROVIDERS")}</h3>
            <div className="t3-providers-parent-box">
              <Slider {...providerSlick} infinite={providerList.length >= 4}>
                {providerList &&
                  providerList.length &&
                  map(providerList, (item, index) => {
                    return (
                      item && (
                        <div key={index} className="t3-provider-item">
                          <div className="first">
                            <img
                              src={item}
                              className="img-responsive"
                              alt="provider"
                            />
                          </div>
                        </div>
                      )
                    );
                  })}
              </Slider>
            </div>
          </div>

          <div className="t3-game-list-container mt-5">
            <div className="t3-game-list-title-with-arrows-box mb-4">
              <div className="first">
                <h3 className="t3-custom-title">
                  {t("RECOMMENDED")} <span>{t("LIVE")}</span>{" "}{t("CASINO").toUpperCase()}
                </h3>
              </div>
              <div className="second">
                <div className="t3-game-item-count-box" style={{ display: "none" }}>
                  {t("ALL")} <span>{recommendedCasinoGamesCount}</span>{" "}
                  {t("CASINO")}
                </div>
                {liveSlickRef?.current?.innerSlider.props.children.length > 1 && <>
                  <div className="t3-custom-slick-arrow">
                    <div onClick={() => liveSlickRef?.current?.slickPrev()}>
                      <VIcon
                        icon={themeIcons.angleLeft}
                        className="theme-icon-size-20"
                      />
                    </div>
                    <div onClick={() => liveSlickRef?.current?.slickNext()}>
                      <VIcon
                        icon={themeIcons.angleRight}
                        className="theme-icon-size-20"
                      />
                    </div>
                  </div>
                </>}
              </div>
            </div>
            {recommendedCasinoGames.length == 0 && (
              <div className="spinner-wrapper">
                <LoadingGames />
              </div>
            )}
            <div className="t3-slot-games-box">
              <Slider
                ref={liveSlickRef}
                {...slotSlick}
                className="t3-live-game-slick"
              >
                {recommendedCasinoGames &&
                  recommendedCasinoGames.length &&
                  map(recommendedCasinoGames, (item, index) => {
                    return (
                      <div key={index} className="t3-game-list-box">
                        {item &&
                          item.length &&
                          map(item, (game, index2) => {
                            return (
                              <div
                                key={index2}
                                className="t3-game-list-item"
                                onClick={(e) => {
                                  if (dragging) {
                                    e.preventDefault();
                                  } else {
                                    if (heartBeat) {
                                      setPopupBannerImage(
                                        game["popupBannerImage"]
                                      );
                                      if (game["gameUm"] !== true) {
                                        if (
                                          walletsDetails.isValidForPromotion
                                        ) {
                                          _dispatch(
                                            showMessage({
                                              custom: true,
                                              customIcon: (
                                                <img
                                                  className="m-auto"
                                                  src={alertIcon}
                                                  height="120px"
                                                  width="120px"
                                                  alt="Custom Icon"
                                                />
                                              ),
                                              content: t(
                                                "PLEASE_CLAIM_PROMO_BEFORE_ENTER_GAME"
                                              ),
                                              showCancel: true,
                                              onConfirm: () => {
                                                setTransactionValidForPromo();
                                                startGame(
                                                  game["id"],
                                                  game["hasOwnLobby"],
                                                  game["masterProductId"],
                                                  game["gameName"],
                                                  game[
                                                  "masterProductIsSeamless"
                                                  ],
                                                  game["isApp"],
                                                  game["appUrl"],
                                                  game["appDeepLink"],
                                                  game["isNewWindow"],
                                                  game["packageNameKeyword"]
                                                );
                                              },
                                            })
                                          );
                                        } else {
                                          startGame(
                                            game["id"],
                                            game["hasOwnLobby"],
                                            game["masterProductId"],
                                            game["gameName"],
                                            game["masterProductIsSeamless"],
                                            game["isApp"],
                                            game["appUrl"],
                                            game["appDeepLink"],
                                            game["isNewWindow"],
                                            game["packageNameKeyword"]
                                          );
                                        }
                                      }
                                    } else {
                                      _dispatch(setLoginModal(true));
                                    }
                                  }
                                }}
                              >
                                <div className="t3-game-list-image-box">
                                  <div className="image">
                                    <div className="first">
                                      <FallBackImage
                                        src={game.image}
                                        alt={game.gameName}
                                        fallbackSrc={defaultGameIcon}
                                      />
                                    </div>
                                    <div className="second">
                                      <svg
                                        className="play-icon"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 63 63"
                                      >
                                        <defs></defs>
                                        <g fill="none" fillRule="evenodd">
                                          <circle
                                            cx="31.3"
                                            cy="31.3"
                                            r="30.3"
                                            fill="currentColor"
                                            fillOpacity=".2"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                          ></circle>
                                          <path
                                            fill="currentColor"
                                            d="M39.5 34.3l-11.3 7.5a2 2 0 01-3-1.6v-15a2 2 0 013-1.7L39.5 31a2 2 0 010 3.3z"
                                          ></path>
                                        </g>
                                      </svg>
                                    </div>
                                  </div>
                                </div>
                                <div className="t3-game-list-text-box">
                                  <h6>{game["gameName"]}</h6>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    );
                  })}
              </Slider>
            </div>
          </div>

          {/* {latestBets && latestBets.length > 0 && (
						<div className="mt-5">
							<h3 className="t3-custom-title mb-3">
								{t("LATEST")} <span>{t("BETS")} </span>
							</h3>
							<div>
								<div className="t3-table-header">
									<span>{t("GAME")}</span>
									<span>{t("PLAYER")}</span>
									<span>{t("BETS")}</span>
									<span>{t("NET_PROFIT")}</span>
								</div>
							</div>
							<div className="t3-scroll-row" id="t3-scroll-row">
								<div className="t3-scroll-container" id="t3-scroll-container">
									{latestBets &&
										latestBets.length > 0 &&
										map(latestBets, (item, index) => {
											return (
												<div key={index} className="t3-scroll-item">
													<div className="t3-scroll-item-child">
														<span>
															<img
																src={item.image}
																className="img-responsive"
																alt={item.text}
															/>
															<b>{item.gameName}</b>
														</span>
														<span>
															{item.player.substring(0, 2) +
																"*".repeat(item.player.length - 2)}
														</span>
														<span>{item.validTurnover}</span>
														<span
															className={
																item.profit >= 0
																	? "t3-text-green"
																	: "t3-text-red"
															}
														>
															{item.profit}
														</span>
													</div>
												</div>
											);
										})}
								</div>
							</div>
						</div>
					)} */}
        </div>
      </div>

      {/* /////////t3 footer */}
      {/* <Footer /> */}

      {/* ///pregame popup */}
      <GameLobbyModal
        masterProductId={masterProductId}
        selectedGameIsSeamless={selectedGameIsSeamless}
        selectedGameName={selectedGameName}
        productGameId={productGameId}
        popupBannerImage={popupBannerImage}
        category={t("HOME")}
      />

      <Modal
        fade={false}
        id="t3-custom-modal-withdraw"
        isOpen={transferShow}
        toggle={() => {
          setTransferShow(false);
        }}
      // centered
      >
        <ModalBody>
          <div className="t3-custom-modal-withdraw-container t3-modal-bg-dark">
            <div className="t3-cms-header">
              <div className="first">
                <div>{t("TRANSFER_BALANCE")}</div>
              </div>
              <div className="second">
                <div
                  className="t3-close-custom-modal"
                  onClick={() => {
                    setTransferShow(false);
                    setToGameAmount(0);
                    setPromoId("");
                    setShowPregamePopup(true);
                  }}
                >
                  <VIcon icon={themeIcons.close} className="vicon" />
                </div>
              </div>
            </div>
            <div className="t3-withdraw-modal-body">
              <form onSubmit={handleSubmitPopup(submitPopupForm)}>
                <div className="t3-withdraw-form-style-box">
                  <label>{t("AMOUNT_TO_TRANSFER")}</label>
                </div>
                <div className="urr-amount-box">
                  <div>
                    <input
                      type="text"
                      name="amount"
                      value={Number(toGameAmount).toFixed(2)}
                      placeholder={numberWithCurrencyFormat(
                        parseFloat(toGameAmount),
                        2,
                        true
                      )}
                      className="input-with-balance"
                      onChange={(e) => {
                        let amount = e.target.value
                          .replace(
                            /[a-zA-Z`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?(kr|$||)]*/g,
                            ""
                          )
                          .trim();

                        let value = Number(parseFloat(amount) / 100).toFixed(2);
                        if (
                          parseFloat(value) > parseFloat(fromOptions[0].amount)
                        ) {
                          value = fromOptions[0].amount;
                        }
                        e.target.setSelectionRange(value.length, value.length);
                        setInputValue(value);
                        setToGameAmount(value);
                      }}
                      disabled={
                        fromOptions.length > 0 &&
                          fromOptions[0].amount >
                          numberWithCurrencyFormat(parseFloat(0), 2, true)
                          ? ""
                          : "disabled"
                      }
                    />
                  </div>
                  <span className="font10 text-lgray2">
                    {t("AVAILABLE_BALANCE")}
                    {fromOptions.length > 0
                      ? numberWithCurrencyFormat(fromOptions[0].amount, 2)
                      : ""}
                  </span>
                </div>
                <span className="font12 text-lgray2 justify-center mt-2">
                  {t("Min")}:{" "}
                  {promoId != ""
                    ? numberWithCurrencyFormat(
                      promoCodeList.filter(
                        (x) => x.id == parseInt(promoId)
                      )[0].minDeposit,
                      2,
                      true
                    )
                    : numberWithCurrencyFormat(1, 2, true)}
                </span>
                <div
                  className="all-box-with-icon mt-2"
                  onClick={() => {
                    setPromoShow(true);
                    setTransferShow(false);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <div className="first">
                    <div>
                      <VIcon
                        icon={themeIcons.dashboardCommission}
                        className="vicon"
                      />
                    </div>
                    <div className="font14 f-weight-500 text-lblue">
                      {promoId == ""
                        ? t("PROMO_CODE")
                        : promoCodeList.filter(
                          (x) => x.id == parseInt(promoId)
                        )[0].name}
                    </div>
                  </div>
                  <div>
                    <VIcon icon={themeIcons.angleRight} className="vicon" />
                  </div>
                </div>
                {promoId != null &&
                  promoCodeList
                    .filter((x) => x.id == parseInt(promoId))
                    .map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="reports-stats-box mb-3 mt-4"
                        >
                          <div className="ro-box-1-1">
                            <div className="earn-stat-title mb-1">
                              <div className="first-title">{t("BONUS")} %</div>
                              <div className="d-flex align-items-center"></div>
                            </div>
                            <div
                              className="earn-stat-number mb-1"
                              style={{ textAlign: "right" }}
                            >
                              {" "}
                              {item.bonus}
                            </div>
                          </div>

                          <div className="ro-box-1-1">
                            <div className="earn-stat-title mb-1">
                              <div className="first-title">Rollover</div>
                            </div>
                            <div
                              className="earn-stat-number mb-1"
                              style={{ textAlign: "right" }}
                            >
                              {" "}
                              x{item.rollover}
                            </div>
                          </div>
                        </div>
                      );
                    })}

                <div className="mt-2">
                  {bonus > 0 && (
                    <>
                      <span className="font12 text-lgray2">
                        {t("BONUS")}:{" "}
                        {numberWithCurrencyFormat(parseFloat(bonus), 2, true)}%
                      </span>
                      <br />
                    </>
                  )}
                </div>
                <div className="text-center">
                  <button
                    type="submit"
                    className={
                      inputValue > 0
                        ? "t3-custom-light-btn mt-4 mb-4"
                        : "t3-custom-light-btn mt-4 mb-4 disabled"
                    }
                  >
                    {t("TRANSFER_TO_WALLET")}
                    {transferLoading ? (
                      <Spinner
                        style={{
                          verticalAlign: "middle",
                          width: "1rem",
                          height: "1rem",
                          marginLeft: 5,
                        }}
                      ></Spinner>
                    ) : (
                      ""
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        fade={false}
        id="t3-custom-modal-withdraw"
        isOpen={promoShow}
        toggle={() => {
          setPromoShow(false);
        }}
        centered
      >
        <ModalBody>
          <div className="t3-custom-modal-withdraw-container t3-modal-bg-dark">
            <div className="t3-cms-header">
              <div className="first">
                <div>{t("PROMO_CODE")}</div>
              </div>
              <div className="second">
                <div
                  className="t3-close-custom-modal"
                  onClick={() => {
                    setPromoShow(false);
                    setTransferShow(true);
                  }}
                >
                  <VIcon icon={themeIcons.close} className="vicon" />
                </div>
              </div>
            </div>
            <div className="t3-withdraw-modal-body pb-5">
              <form>
                <div className="t3-withdraw-form-style-box">
                  <label>{t("SELECT_PROMO_CODE")}</label>
                </div>
                <div
                  className="all-box-with-icon mt-2"
                  onClick={() => {
                    setPromoId("");
                    setPromoShow(false);
                    setTransferShow(true);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <div className="first">
                    <div>
                      <VIcon
                        icon={themeIcons.dashboardCommission}
                        className="vicon"
                      />
                    </div>
                    <div className="font14 f-weight-500 text-lblue">
                      {t("NO_BONUS")}
                    </div>
                  </div>
                </div>
                {promoCodeList
                  .filter((x) => x.productId == masterProductId)
                  .map((promo, index) => {
                    return (
                      <div
                        key={index}
                        className="all-box-with-icon mt-2"
                        onClick={() => {
                          setPromoId(promo.id);
                          setPromoShow(false);
                          setTransferShow(true);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <div className="first">
                          <div>
                            <VIcon
                              icon={themeIcons.dashboardCommission}
                              className="vicon"
                            />
                          </div>
                          <div className="font14 f-weight-500 text-lblue">
                            {selectedGameName} - {promo.name}
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </form>
            </div>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        id="t3-login-register-modal"
        fade={false}
        className={"home-announcement-modal"}
        contentClassName={"t3-login-register-modal"}
        isOpen={showAnnouncementModal}
        toggle={() => {
          setShowAnnouncementModal(false);
        }}
        centered
        fullscreen
      >
        <ModalBody>
          <div className="t3-lr-modal-container pb-5">
            <div className="t3-lr-modal-header">
              <div className="t3-logo">
                {logo ? (
                  <img src={logo} className="img-responsive" alt="logo" />
                ) : (
                  <PlaceholderLoading
                    shape="rect"
                    width={100}
                    height={40}
                    colorStart="#333"
                    colorEnd="#636363"
                  />
                )}
              </div>
              <div
                className="t3-close-modal"
                // onClick={() => setShowModalRegister(!showModalRegister)}
                onClick={() => closePopUp()}
              >
                <VIcon icon={themeIcons.close} className="vicon" />
              </div>
            </div>
            <div className="t3-lr-modal-body">
              <div className="home-announcement-modal-content-box">
                {announcementList &&
                  announcementList.length > 0 &&
                  map(announcementList, (item, index) => {
                    return (
                      <div key={index} className="item">
                        <div className="details mb-2">{item.title}</div>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.content,
                          }}
                        />
                      </div>
                    );
                  })}
              </div>
              <div className="text-center mt-4">
                <button
                  className="t3-custom-light-btn"
                  type="submit"
                  onClick={() => closePopUp()}
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>

      {isLoading ? <LoadingPlaceholder /> : ""}
    </>
  );
};

export default Login;
